jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchstart", handle, { passive: false });
      } else {
        this.addEventListener("touchstart", handle, { passive: true });
      }
    }
  };
  
  jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchmove", handle, { passive: false });
      } else {
        this.addEventListener("touchmove", handle, { passive: true });
      }
    }
  };

  /**** Main menu ****/
  
  $('.dropdown-toggle').dropdown();
  
  function checkWidth() {
    if ($(window).width() < 975) {
      $(".navi li ul").hide();
  
    } else {
      $(".navi li ul").attr('style', '');
  
    }
  }
  
  checkWidth();
  
  $(window).on("resize", function () {
    checkWidth();
    $('a.colapse').hide();
    $('a.expand').show();
  });
  
  $(".navi li").each(function () {
    var li = $(this);
    if (li.find('ul li').length > 0) {
      li.append("<a href='javascript:;' rel='nofollow noopener' class='expand icon-expand' onclick='return false;'></a>");
      li.append("<a href='javascript:;' rel='nofollow noopener' class='colapse icon-collapse' onclick='return false;'></a>");
    }
  });
  
  $('body').on('click', 'a.expand', function () {
    $(this).hide().next().show();
    $(this).parent().siblings().find('ul').slideUp();
    $(this).parent().siblings().find('a.expand').show();
    $(this).parent().siblings().find('a.colapse').hide();
    $(this).parent().find('>ul').addClass('expanded').stop(true, true).slideDown();
  });
  
  $('body').on('click', 'a.colapse', function () {
    $(this).parent().find('ul').removeClass('expanded').stop(true, true).slideUp();
    $(this).parent().find('a.colapse').hide();
    $(this).parent().find('a.expand').show();
  });
  
  $(".hamburger").on('click', function () {
    $(".hamburger").toggleClass("is-active");
    $(".header-container").toggleClass("navi-open");
    $("body").toggleClass("navi-open");
  });
  

  $(".show-more").on('click', function(){
    $(this).closest('li.level1').find('ul.level2').toggle();
    $(this).closest('li.level1').toggleClass('open');
  })
  /**** Main menu end ****/

  
  if ($('.gallery-slider').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {
      var splide = new Splide('.gallery-slider', {
        rewind: true,
        arrows: true,
        pagination: true,
        perPage: 1,
        drag: true,
        speed: 1500,
        rewind: true,
        rewindSpeed: 1500
      }).mount();
    });
  }